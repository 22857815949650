import FollowProfileButton from 'containers/FollowProfileButton';
import { UserInlineContainer } from 'containers/inline/UserInlineContainer';
import { useHideActionBlockMutation, useRecFollowsByBooksQuery } from 'generated/graphql';
import { useSessionStorage } from 'hooks/useSessionStorage';
import { useMemo, useState } from 'react';
import { renderDontShowActionItem, renderStopActionItem } from './BlockActionItems';
import DefaultLayout from './DefaultLayout';
import LiteralBlock from './LiteralBlock';
import { ActionBlockProps } from './Type';

export const SimilarTasteBlock = ({ setHiddenStatus }: ActionBlockProps): JSX.Element | null => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hideActionBlock] = useHideActionBlockMutation();
  const { data } = useRecFollowsByBooksQuery();
  const [profilesToIgnore, setProfilesToIgnore] = useSessionStorage<string[]>('ProfilesToIgnore', []);

  const hide = async () => {
    const hidden = await hideActionBlock({ variables: { targetId: 'PeopleWithSimilarTaste' } });
    if (hidden) {
      setDropdownOpen(false);
      setHiddenStatus(true);
    }
  };

  const rec = useMemo(() => {
    return data?.recFollowsByBooks.filter(
      (item) => !profilesToIgnore.some((id) => id === item.profile.id)
    )[0];
  }, [data, profilesToIgnore]);

  if (!rec) return null;

  const stopShowingThisPerson = async () => {
    setProfilesToIgnore([...profilesToIgnore, rec.profile.id]);
    setDropdownOpen(false);
    await hideActionBlock({ variables: { targetId: `profile:${rec?.profile.id}` } });
  };

  const dontShowActionItem = renderDontShowActionItem({ onClick: hide });

  const stopActionItem = renderStopActionItem({ onClick: stopShowingThisPerson, recType: 'person' });

  return (
    <LiteralBlock
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      headerText="Similar taste as you"
      dropdownItems={[stopActionItem, dontShowActionItem]}
    >
      <DefaultLayout
        text={
          <UserInlineContainer
            link={true}
            right={<FollowProfileButton dontInvalidateFeedCache profile={rec.profile} mini />}
            profile={rec.profile}
            books={rec.books}
          />
        }
      />
    </LiteralBlock>
  );
};

export default SimilarTasteBlock;
