import { ActionBlockKey, useActionBlocksQuery } from 'generated/graphql';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import { shuffleArray, shuffleByOtherArray } from 'utils/array';
import { useDeviceDetectContext } from './useDeviceDetect';
import { useSessionStorage } from './useSessionStorage';

const allActionBlocks: ActionBlockKey[] = [
  ActionBlockKey.DownloadApp,
  ActionBlockKey.PeopleWithSimilarTaste,
  ActionBlockKey.LatestBlog,
  ActionBlockKey.Feedback,
  ActionBlockKey.FriendOfFriends,
  ActionBlockKey.RandomWantToRead,
  ActionBlockKey.BookRec,
  ActionBlockKey.RandomBook,
  ActionBlockKey.FollowUs,
  ActionBlockKey.BecomePatron,
  ActionBlockKey.BecomeLibrarian,
];

type ActionBlocksContextValues = {
  availableShuffledBlocks: ActionBlockKey[];
  refetchActionBlocks: () => void;
};

const defaultContextValues: ActionBlocksContextValues = {
  availableShuffledBlocks: [],
  refetchActionBlocks: () => {},
};

export const ActionBlocksContext = React.createContext<ActionBlocksContextValues>(defaultContextValues);

export const ActionBlocksContextProvider: FC = ({ children }) => {
  const { isMobileApp } = useDeviceDetectContext();
  const { data: availableActionBlocks, refetch: refetchActionBlocks } = useActionBlocksQuery();
  const [shuffled, setShuffled] = useSessionStorage<ActionBlockKey[]>('ShuffledActionBlocks', []);

  useEffect(() => {
    if (shuffled.length > 0) return;
    const shuffledBlocks = shuffleArray(allActionBlocks);
    setShuffled(shuffledBlocks);
  }, []);

  const availableShuffledBlocks: ActionBlockKey[] = useMemo(() => {
    if (!availableActionBlocks || !shuffled) return [];
    return shuffleByOtherArray(
      shuffled,
      availableActionBlocks.actionBlocks.filter((block) => {
        if (isMobileApp) return block !== ActionBlockKey.DownloadApp;
        else return true;
      })
    );
  }, [availableActionBlocks, shuffled]);

  return (
    <ActionBlocksContext.Provider value={{ availableShuffledBlocks, refetchActionBlocks }}>
      {children}
    </ActionBlocksContext.Provider>
  );
};

export const useActionBlocksContext = (): ActionBlocksContextValues => {
  try {
    const actionBlocksContext = useContext(ActionBlocksContext);
    return actionBlocksContext;
  } catch {
    return defaultContextValues;
  }
};

export default useActionBlocksContext;
