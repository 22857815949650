import { useHideActionBlockMutation } from 'generated/graphql';
import { useState } from 'react';
import { LinkSimple } from 'ui/generic';
import { SocialFacebookIcon, SocialInstagramIcon, SocialTikTokIcon, SocialTwitterIcon } from 'ui/icons';
import { renderDontShowActionItem } from './BlockActionItems';
import DefaultLayout from './DefaultLayout';
import styles from './FollowUsBlock.module.scss';
import LiteralBlock from './LiteralBlock';

type Props = {
  setHiddenStatus: (hidden: boolean) => void;
};

const FollowUsBlock = ({ setHiddenStatus }: Props): JSX.Element => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hideActionBlock] = useHideActionBlockMutation();

  const hide = async () => {
    const hidden = await hideActionBlock({ variables: { targetId: 'FollowUs' } });
    if (hidden) {
      setDropdownOpen(false);
      setHiddenStatus(true);
    }
  };

  const dontShowActionItem = renderDontShowActionItem({ onClick: hide });

  return (
    <LiteralBlock
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      headerText="Follow Literal wherever you are"
      dropdownItems={[dontShowActionItem]}
    >
      <DefaultLayout
        hideOnClick={hide}
        text={
          <div className={styles.container}>
            <LinkSimple href="https://twitter.com/literalclub" rel="noreferrer" target="_blank">
              <SocialTwitterIcon />
            </LinkSimple>
            <LinkSimple href="https://www.instagram.com/literalclub/" target="_blank" rel="noreferrer">
              <SocialInstagramIcon />
            </LinkSimple>
            <LinkSimple href="https://www.facebook.com/literalclub" target="_blank" rel="noreferrer">
              <SocialFacebookIcon />
            </LinkSimple>
            <LinkSimple href="https://tiktok.com/@literalclub" target="_blank" rel="noreferrer">
              <SocialTikTokIcon />
            </LinkSimple>
          </div>
        }
      />
    </LiteralBlock>
  );
};

export default FollowUsBlock;
