import BookItem from 'components/book/ItemFeed';
import BookControls from 'containers/BookControls';
import { useHideActionBlockMutation, useRecBooksQuery } from 'generated/graphql';
import { useSessionStorage } from 'hooks/useSessionStorage';
import { useMemo, useState } from 'react';
import { routes } from 'utils/routes';
import { renderDontShowActionItem, renderStopActionItem } from './BlockActionItems';
import DefaultLayout from './DefaultLayout';
import LiteralBlock from './LiteralBlock';
import { ActionBlockProps } from './Type';

export const BookRecBlock = ({ setHiddenStatus }: ActionBlockProps): JSX.Element | null => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isBookOptionsOpen, setBookOptionsOpen] = useState<string>();
  const [hideActionBlock] = useHideActionBlockMutation();
  const { data } = useRecBooksQuery();
  const [booksToIgnore, setBooksToIgnore] = useSessionStorage<string[]>('BooksToIgnore', []);

  const hide = async () => {
    const hidden = await hideActionBlock({ variables: { targetId: 'BookRec' } });
    if (hidden) {
      setDropdownOpen(false);
      setHiddenStatus(true);
    }
  };

  const rec = useMemo(() => {
    return data?.recBooks.filter((item) => !booksToIgnore.some((id) => id === item.id))[0];
  }, [data, booksToIgnore]);

  if (!rec) return null;

  const stopShowingThisBook = async () => {
    setBooksToIgnore([...booksToIgnore, rec.id]);
    setDropdownOpen(false);
    await hideActionBlock({ variables: { targetId: `book:${rec?.id}` } });
  };

  const bookUrl = `/book/${rec.slug}`;

  const dontShowActionItem = renderDontShowActionItem({ onClick: hide });

  const stopActionItem = renderStopActionItem({ onClick: stopShowingThisBook, recType: 'book' });

  return (
    <LiteralBlock
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      headerText="Recommended for you"
      dropdownItems={[stopActionItem, dontShowActionItem]}
    >
      <DefaultLayout
        text={
          <BookItem
            noBorder
            book={rec}
            href={routes.globalBook}
            as={bookUrl}
            onLongPress={() => setBookOptionsOpen(rec.id)}
            actions={
              <BookControls
                book={rec}
                mini
                isOpen={(rec.id && isBookOptionsOpen === rec.id) || false}
                setOpen={(newVal) => {
                  newVal ? setBookOptionsOpen(rec.id) : setBookOptionsOpen(undefined);
                }}
              />
            }
          />
        }
      />
    </LiteralBlock>
  );
};

export default BookRecBlock;
