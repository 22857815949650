import { PrivateIcon, StopIcon } from 'ui/icons';
import { ActionItemType } from 'ui/specific/actionList';

type Props = {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  recType?: 'person' | 'post' | 'book' | 'club';
};

export const renderStopActionItem = ({ onClick, recType }: Props): ActionItemType => {
  return { icon: <StopIcon />, onClick: onClick, title: `Stop recommending this ${recType}` };
};

export const renderDontShowActionItem = ({ onClick }: Props): ActionItemType => {
  return { icon: <PrivateIcon />, onClick: onClick, title: "Don't show this again" };
};
