import { IconProps } from 'ui/icons/type';

export const VoteIllustration = ({ color = 'currentColor' }: IconProps): JSX.Element => {
  return (
    <svg width="66" height="54" viewBox="0 0 66 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.2623 35.3882C22.7278 35.3346 22.1091 38.3857 24.1551 38.4571C26.2011 38.5286 30.2931 38.6715 30.2931 38.6715C30.2931 38.6715 28.1213 42.2017 28.0149 45.2495C27.9085 48.2973 31.4696 48.6647 31.5876 45.2856C31.7056 41.9065 37.526 39.354 37.9298 39.9624L37.9119 40.4739L42.0275 40.6176C42.5794 40.6369 43.0425 40.2051 43.0618 39.6531L43.4385 28.8645C43.4578 28.3126 43.026 27.8495 42.474 27.8302L38.3585 27.6865L38.3227 28.7095C38.3227 28.7095 34.9933 27.3794 32.7499 26.9785C30.192 26.5215 27.6349 26.7999 26.1004 26.7463C24.566 26.6928 24.4767 29.2488 26.0112 29.3024M24.2623 35.3882C22.7278 35.3346 22.8399 32.1238 24.3744 32.1774M24.2623 35.3882L25.1656 35.4197M24.3744 32.1774C24.3744 32.1774 26.4204 32.2488 24.8859 32.1952C23.3514 32.1416 23.4537 29.2131 24.9882 29.2666C26.5227 29.3202 26.0112 29.3024 26.0112 29.3024M24.3744 32.1774L25.9089 32.2309M26.0112 29.3024L26.5064 29.3197"
        stroke={color}
        strokeWidth="1.8033"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.4168 11.3186C39.0132 14.5975 34.7589 19.9727 34.0665 19.5973L33.8249 19.101L29.7606 21.0797C29.2641 21.3215 29.0575 21.92 29.2993 22.4166L34.465 33.0267C34.7068 33.5233 35.3053 33.7298 35.8019 33.4881L39.8661 31.5093L39.3828 30.5166C39.3828 30.5166 43.2275 30.1001 45.5675 29.3475C48.2354 28.4894 50.5308 26.931 52.0198 26.206C53.5088 25.4811 52.3013 23.0008 50.8122 23.7258L51.8049 23.2425C53.2939 22.5175 51.9103 19.6758 50.4213 20.4007C48.9323 21.1257 50.9177 20.1591 50.9177 20.1591C52.4067 19.4341 50.8898 16.3185 49.4008 17.0434C50.8898 16.3185 49.9362 13.0988 47.9509 14.0654L41.9948 16.9652C41.9948 16.9652 42.2786 12.5037 40.8387 9.54626C39.3988 6.58877 35.8203 8.0396 37.4168 11.3186Z"
        fill="var(--uiBoxBackground)"
      />
      <path
        d="M49.4008 17.0434C50.8898 16.3185 49.9362 13.0988 47.9509 14.0654C45.9655 15.032 41.9948 16.9652 41.9948 16.9652C41.9948 16.9652 42.2786 12.5037 40.8387 9.54626C39.3988 6.58877 35.8203 8.0396 37.4168 11.3186C39.0132 14.5975 34.7589 19.9727 34.0665 19.5973L33.8249 19.101L29.7606 21.0797C29.2641 21.3215 29.0575 21.92 29.2993 22.4166L34.465 33.0267C34.7068 33.5233 35.3053 33.7298 35.8019 33.4881L39.8661 31.5093L39.3828 30.5166C39.3828 30.5166 43.2275 30.1001 45.5675 29.3475C48.2354 28.4894 50.5308 26.931 52.0198 26.206C53.5088 25.4811 52.3013 23.0008 50.8122 23.7258M49.4008 17.0434C50.8898 16.3185 52.4067 19.4341 50.9177 20.1591M49.4008 17.0434L48.5242 17.4702M50.9177 20.1591C50.9177 20.1591 48.9323 21.1257 50.4213 20.4007C51.9103 19.6758 53.2939 22.5175 51.8049 23.2425C50.3159 23.9674 50.8122 23.7258 50.8122 23.7258M50.9177 20.1591L49.4287 20.884M50.8122 23.7258L50.3317 23.9597"
        stroke={color}
        strokeWidth="1.94504"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DiceIllustration = ({ color = 'currentColor' }: IconProps): JSX.Element => {
  return (
    <svg width="55" height="48" viewBox="0 0 55 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="6.53516"
        y="17.7025"
        width="23.6794"
        height="23.6794"
        rx="2.95993"
        transform="rotate(-6.32414 6.53516 17.7025)"
        fill="var(--uiBoxBackground)"
        stroke={color}
        strokeWidth="2.36794"
      />
      <ellipse
        cx="15.0303"
        cy="33.8848"
        rx="2.21995"
        ry="2.21995"
        transform="rotate(-96.3241 15.0303 33.8848)"
        fill={color}
      />
      <circle cx="19.6074" cy="28.166" r="2.21995" transform="rotate(-96.3241 19.6074 28.166)" fill={color} />
      <ellipse
        cx="24.1865"
        cy="22.447"
        rx="2.21995"
        ry="2.21995"
        transform="rotate(-96.3241 24.1865 22.447)"
        fill={color}
      />
      <rect
        x="24.582"
        y="7.25131"
        width="23.6794"
        height="23.6794"
        rx="2.95993"
        transform="rotate(8.82023 24.582 7.25131)"
        fill="var(--uiBoxBackground)"
        stroke={color}
        strokeWidth="2.36794"
      />
      <ellipse
        cx="28.5526"
        cy="25.0908"
        rx="2.21995"
        ry="2.21995"
        transform="rotate(-81.1798 28.5526 25.0908)"
        fill={color}
      />
      <ellipse
        cx="34.4657"
        cy="20.7665"
        rx="2.21995"
        ry="2.21995"
        transform="rotate(-81.1798 34.4657 20.7665)"
        fill={color}
      />
      <ellipse
        cx="40.3778"
        cy="16.4421"
        rx="2.21995"
        ry="2.21995"
        transform="rotate(-81.1798 40.3778 16.4421)"
        fill={color}
      />
    </svg>
  );
};

export const PinsIllustration = ({ color = 'currentColor' }: IconProps): JSX.Element => {
  return (
    <svg width="58" height="54" viewBox="0 0 58 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0635 39.0965L7.4172 30.592C7.03432 30.1021 7.18664 29.3852 7.73566 29.0933L18.3174 23.4669C18.8664 23.175 19.5459 23.4496 19.7379 24.041L23.0719 34.3066C23.1514 34.5516 23.3561 34.7352 23.6082 34.7879L28.5577 35.8224C28.6035 35.832 28.6122 35.8936 28.5709 35.9155L12.3223 44.5551C12.281 44.577 12.2349 44.5353 12.2525 44.492L14.1626 39.8102C14.2599 39.5717 14.2221 39.2994 14.0635 39.0965Z"
        stroke={color}
        strokeWidth="2.29894"
      />
      <path
        d="M22.9521 47.3962C23.2501 47.9567 23.9461 48.1695 24.5067 47.8715C25.0672 47.5734 25.28 46.8774 24.9819 46.3169L22.9521 47.3962ZM19.6462 41.1787L22.9521 47.3962L24.9819 46.3169L21.6761 40.0995L19.6462 41.1787Z"
        fill={color}
      />
      <path
        d="M27.1649 26.1008L27.6547 15.3185C27.6829 14.6974 28.2651 14.2523 28.8719 14.388L40.5674 17.0042C41.1742 17.1399 41.5113 17.7907 41.2722 18.3646L37.1214 28.328C37.0224 28.5658 37.0582 28.8384 37.2153 29.0425L40.2994 33.0495C40.328 33.0865 40.2945 33.139 40.2489 33.1288L22.29 29.1115C22.2444 29.1013 22.2365 29.0396 22.2781 29.0183L26.7755 26.7072C27.0046 26.5895 27.1532 26.3582 27.1649 26.1008Z"
        stroke={color}
        strokeWidth="2.29894"
      />
      <path
        d="M28.51 38.1874C28.3714 38.8069 28.7613 39.4215 29.3808 39.5601C30.0004 39.6987 30.6149 39.3088 30.7535 38.6893L28.51 38.1874ZM30.0472 31.3155L28.51 38.1874L30.7535 38.6893L32.2907 31.8174L30.0472 31.3155Z"
        fill={color}
      />
    </svg>
  );
};

export const PatronIllustration = ({ color = 'currentColor' }: IconProps): JSX.Element => {
  return (
    <svg width="55" height="39" viewBox="0 0 55 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.72956 14.0152L3.97397 19.1661M7.72956 14.0152L14.277 14.2439M7.72956 14.0152L10.2238 19.3844M20.8245 14.4725L24.2116 19.8728M20.8245 14.4725L14.277 14.2439M20.8245 14.4725L17.9618 19.6546M24.2116 19.8728L13.6488 32.2329M24.2116 19.8728L17.9618 19.6546M3.97397 19.1661L13.6488 32.2329M3.97397 19.1661L10.2238 19.3844M10.2238 19.3844L17.9618 19.6546M10.2238 19.3844L13.6488 32.2329M10.2238 19.3844L14.277 14.2439M13.6488 32.2329L17.9618 19.6546M17.9618 19.6546L14.277 14.2439"
        stroke={color}
        strokeWidth="1.6"
        strokeLinejoin="round"
      />
      <path
        d="M33.4729 7.08966L29.8994 12.4016M33.4729 7.08966H40.0244M33.4729 7.08966L36.1531 12.4016M46.5759 7.08966L50.1494 12.4016M46.5759 7.08966H40.0244M46.5759 7.08966L43.8957 12.4016M50.1494 12.4016L40.0244 25.2024M50.1494 12.4016H43.8957M29.8994 12.4016L40.0244 25.2024M29.8994 12.4016H36.1531M36.1531 12.4016H43.8957M36.1531 12.4016L40.0244 25.2024M36.1531 12.4016L40.0244 7.08966M40.0244 25.2024L43.8957 12.4016M43.8957 12.4016L40.0244 7.08966"
        stroke={color}
        strokeWidth="1.6"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const LibrarianIllustration = ({ color = 'currentColor' }: IconProps): JSX.Element => {
  return (
    <svg width="55" height="39" viewBox="0 0 55 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7247 14.5839C18.3366 14.3726 17.8508 14.516 17.6395 14.904C17.4283 15.2921 17.5716 15.7779 17.9596 15.9892L19.556 16.8583C20.1326 17.1722 20.5389 17.7272 20.6638 18.3718L21.7581 24.017C21.1997 23.731 20.5712 23.5601 19.9027 23.5368C17.8348 23.4646 16.0496 24.8272 15.5057 26.731L15.2143 26.6224C14.4439 26.3353 13.6011 26.3059 12.8125 26.5385L12.5137 26.6266C12.104 24.6895 10.4182 23.2056 8.35021 23.1333C7.68174 23.11 7.04281 23.2366 6.4658 23.4829L7.95124 17.9278C8.12084 17.2936 8.56485 16.7682 9.16197 16.4953L10.8151 15.7397C11.2169 15.556 11.3938 15.0813 11.2101 14.6795C11.0264 14.2776 10.5517 14.1008 10.1499 14.2845L8.49681 15.0401C7.46542 15.5115 6.69849 16.419 6.40555 17.5145L4.25539 25.5555C3.97597 26.1085 3.80917 26.7295 3.78612 27.3894C3.70107 29.825 5.60658 31.8684 8.0422 31.9535C10.2781 32.0316 12.1835 30.4322 12.5467 28.2851L13.2652 28.0732C13.7217 27.9385 14.2097 27.9555 14.6557 28.1217L15.357 28.383C15.5695 30.5503 17.3587 32.2788 19.5947 32.3569C22.0303 32.442 24.0737 30.5365 24.1588 28.1008C24.1818 27.4409 24.0588 26.8098 23.8186 26.2386L22.2346 18.0673C22.0188 16.954 21.317 15.9953 20.321 15.453L18.7247 14.5839ZM16.9366 27.8863C16.9031 29.4222 18.1106 30.7041 19.6506 30.7579C21.1745 30.8111 22.4576 29.6418 22.5555 28.1303L22.2832 26.7258C21.8438 25.8156 20.9271 25.1735 19.8469 25.1358C18.3071 25.082 17.0132 26.2764 16.9392 27.8107C16.9396 27.8358 16.9387 27.861 16.9366 27.8863ZM8.29437 24.7324C9.83785 24.7863 11.0474 26.074 11.0081 27.6146C11.0068 27.6324 11.0062 27.6503 11.0062 27.6682C10.9381 29.208 9.64165 30.4084 8.09804 30.3545C6.57409 30.3013 5.37573 29.0453 5.38344 27.5307L5.75303 26.1485C6.25489 25.2712 7.21411 24.6946 8.29437 24.7324Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.974 7.63593C37.1726 8.03061 37.0136 8.51155 36.6189 8.71013L34.9897 9.52982C34.404 9.82451 33.9803 10.3651 33.834 11.0042L32.5393 16.6603C33.1074 16.3924 33.7419 16.2425 34.4118 16.2425C36.4856 16.2425 38.2198 17.6783 38.6954 19.6089L38.9834 19.5123C39.7676 19.2493 40.6161 19.2493 41.4003 19.5123L41.6878 19.6087C42.1634 17.6783 43.8976 16.2425 45.9713 16.2425C46.6413 16.2425 47.2757 16.3924 47.8438 16.6603L46.5491 11.0042C46.4029 10.3651 45.9791 9.82451 45.3934 9.52982L43.7642 8.71013C43.3695 8.51155 43.2106 8.03061 43.4091 7.63593C43.6077 7.24124 44.0887 7.08226 44.4834 7.28084L46.1125 8.10053C47.1242 8.60954 47.8561 9.54326 48.1088 10.6472L49.9798 18.821C50.2394 19.3862 50.3841 20.0154 50.3841 20.6779C50.3841 23.1228 48.4132 25.1133 45.9713 25.1133C43.7286 25.1133 41.883 23.4341 41.597 21.2659L40.8915 21.0292C40.4375 20.8769 39.9462 20.8769 39.4922 21.0292L38.7861 21.2661C38.5 23.4342 36.6545 25.1133 34.4118 25.1133C31.97 25.1133 29.999 23.1228 29.999 20.6779C29.999 20.0154 30.1438 19.3863 30.4034 18.821L32.2743 10.6472C32.527 9.54326 33.2589 8.60954 34.2706 8.10053L35.8998 7.28084C36.2945 7.08226 36.7754 7.24124 36.974 7.63593ZM43.1588 20.7156C43.1789 22.2688 44.435 23.5133 45.9713 23.5133C47.4921 23.5133 48.7383 22.294 48.7829 20.7629L48.4611 19.3571C47.9891 18.4551 47.0492 17.8425 45.9713 17.8425C44.4351 17.8425 43.179 19.0869 43.1588 20.6399C43.16 20.665 43.16 20.6903 43.1588 20.7156ZM34.4118 17.8425C35.9516 17.8425 37.21 19.0928 37.2244 20.6509C37.2238 20.6687 37.2238 20.6867 37.2244 20.7046C37.2102 22.2629 35.9517 23.5133 34.4118 23.5133C32.8911 23.5133 31.6449 22.294 31.6003 20.7629L31.9221 19.3571C32.394 18.4551 33.3339 17.8425 34.4118 17.8425Z"
        fill={color}
      />
    </svg>
  );
};
