import { useHideActionBlockMutation } from 'generated/graphql';
import { useBlogPosts } from 'hooks/useBlog';
import { useMemo, useState } from 'react';
import { Button, LinkSimple } from 'ui/generic';
import { changeUrlOrigin, extractBlogImageUrl, extractBlogTags } from 'utils/blogPosts';
import { renderDontShowActionItem } from './BlockActionItems';
import DefaultLayout from './DefaultLayout';
import styles from './LatestBlogBlock.module.scss';
import LiteralBlock from './LiteralBlock';
import { ActionBlockProps } from './Type';

const LatetestBlogBlock = ({ setHiddenStatus }: ActionBlockProps): JSX.Element | null => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { blogPosts } = useBlogPosts({ limit: 1 });

  const [hideActionBlock] = useHideActionBlockMutation();

  const hide = async () => {
    const hidden = await hideActionBlock({ variables: { targetId: 'LatestBlog' } });
    if (hidden) {
      setHiddenStatus(true);
    }
  };

  const post = blogPosts[0];
  const dontShowActionItem = renderDontShowActionItem({ onClick: hide });

  const image = useMemo(() => {
    return extractBlogImageUrl(post);
  }, [post]);

  const tags = useMemo(() => {
    return extractBlogTags(post);
  }, [post]);

  if (!blogPosts || blogPosts.length < 1) return null;

  return (
    <LiteralBlock
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      headerText="Latest from the blog"
      dropdownItems={[dontShowActionItem]}
    >
      <DefaultLayout
        actionButton={
          <LinkSimple variant="none" href={changeUrlOrigin(post.link)}>
            <Button mini>Read the post</Button>
          </LinkSimple>
        }
        hideOnClick={hide}
        text={
          <a href={changeUrlOrigin(post.link)}>
            {image && (
              <div className={styles.image}>
                <img src={image} alt="featured image" />
                <div className={styles.tags}>
                  {tags &&
                    tags.map((tag) => (
                      <span key={tag.id} className={styles.tag}>
                        {tag.name}
                      </span>
                    ))}
                </div>
              </div>
            )}
            <p className="uiBaseFocus mb-2">{post.title.rendered}</p>
          </a>
        }
      />
    </LiteralBlock>
  );
};

export default LatetestBlogBlock;
