import { useHideActionBlockMutation, useRecRandomWantToReadBookLazyQuery } from 'generated/graphql';
import { useAuthContext } from 'hooks/useAuth';
import { useRouter } from 'next/router';
import { useState } from 'react';
import Button from 'ui/generic/Button';
import { routes } from 'utils/routes';
import { renderDontShowActionItem } from './BlockActionItems';
import DefaultLayout from './DefaultLayout';
import { PinsIllustration } from './Illustrations';
import LiteralBlock from './LiteralBlock';
import { ActionBlockProps } from './Type';

export const RandomWantToReadBlock = ({ setHiddenStatus }: ActionBlockProps): JSX.Element => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { profile } = useAuthContext();
  const [hideActionBlock] = useHideActionBlockMutation();
  const router = useRouter();
  const [getRandomWantToReadBook] = useRecRandomWantToReadBookLazyQuery();

  const handleRandomWantToReadClick = async () => {
    const { data } = await getRandomWantToReadBook();
    if (data?.recRandomWantToReadBook) {
      router.push(routes.userOwnedBook, `/${profile?.handle}/book/${data.recRandomWantToReadBook.slug}`);
    }
  };

  const hide = async () => {
    const hidden = await hideActionBlock({ variables: { targetId: 'RandomWantToRead' } });
    if (hidden) {
      setDropdownOpen(false);
      setHiddenStatus(true);
    }
  };

  const dontShowActionItem = renderDontShowActionItem({ onClick: hide });

  return (
    <LiteralBlock
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      headerText="Playground"
      dropdownItems={[dontShowActionItem]}
    >
      <DefaultLayout
        hideOnClick={hide}
        text={
          <div>
            <p className="mb-2">Not sure what to read next?</p>
            <p>Let us pick a random book from your Want to read list.</p>
          </div>
        }
        illustration={<PinsIllustration />}
        actionButton={
          <Button mini variant="primary" onClick={handleRandomWantToReadClick}>
            Pick a book
          </Button>
        }
      />
    </LiteralBlock>
  );
};

export default RandomWantToReadBlock;
