import { ReactNode } from 'react';
import { GoalPartsFragment } from 'generated/graphql';
import GoalProgress from './GoalProgress';
import styles from './GoalMini.module.scss';

type Props = {
  goal: GoalPartsFragment;
  progress: number;
  slot?: ReactNode;
};

export const GoalsMini = ({ goal, progress, slot }: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.progress}>
        <GoalProgress size="small" read={progress} target={goal.target} />
      </div>
      <div className={styles.details}>
        <div className={styles.top}>Goal</div>
        <div className={styles.name}>{goal.name}</div>
      </div>
      {slot && <div className={styles.slot}>{slot}</div>}
    </div>
  );
};

export default GoalsMini;
