import { BlogPost, Tag } from 'types/blog';

export function extractBlogTags(post: BlogPost): Tag[] | undefined {
  if (!post || !post._embedded || !post._embedded['wp:term']) return;
  return post._embedded['wp:term']
    .flatMap((x) => x)
    .filter((x) => x.taxonomy === 'category')
    .filter((x) => x.name !== 'Uncategorized');
}

export function extractBlogImageUrl(post: BlogPost): string | undefined {
  if (!post || !post._embedded || !post._embedded['wp:featuredmedia']) return;
  const media = post._embedded['wp:featuredmedia'].find((x) => x.media_type === 'image');
  if (!media) return;
  if (media.media_details?.sizes?.medium_large) {
    return media.media_details.sizes.medium_large.source_url;
  }

  return media.source_url;
}

export function changeUrlOrigin(url: string) {
  const parsedUrl = new URL(url);
  return `${parsedUrl.pathname}${parsedUrl.search}${parsedUrl.hash}`;
}
