import getConfig from 'next/config';
import { useEffect, useState } from 'react';
import { BlogPost } from 'types/blog';

const { publicRuntimeConfig } = getConfig();

type Props = { limit?: number };

export function useBlogPosts({ limit = 20 }: Props) {
  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);
  useEffect(() => {
    getBlogPosts(limit).then(setBlogPosts);
  }, []);

  return { blogPosts };
}

async function getBlogPosts(perPage: number): Promise<BlogPost[]> {
  const fields = [
    'title',
    'modified',
    'modified_gmt',
    'slug',
    'link',
    'date',
    'date_gmt',
    '_embedded',
    '_links',
  ];
  const qs = [
    `per_page=${perPage}`,
    '_embed',
    'orderby=menu_order',
    'order=asc',
    `_fields=${fields.join(',')}`,
  ].join('&');

  const url = `${publicRuntimeConfig.pagesUrl}/wp-json/wp/v2/posts?${qs}`;
  const data: BlogPost[] = await fetch(url)
    .then((res) => res.json())
    .catch(() => []);
  return data;
}
