import classnames from 'classnames';
import { ButtonSimple } from 'ui/generic';
import styles from './DefaultLayout.module.scss';

type Props = {
  text: string | JSX.Element;
  illustration?: JSX.Element;
  actionButton?: JSX.Element;
  hideOnClick?: () => void;
};

const DefaultLayout = ({ hideOnClick, illustration, text, actionButton }: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={classnames(styles.left, { ['uiBaseFocus']: illustration })}>{text}</div>
        {illustration && <div className={styles.right}>{illustration}</div>}
      </div>
      {(hideOnClick || actionButton) && (
        <div className={styles.buttons}>
          <div className={styles.hideButton}>
            <ButtonSimple variant="faded" onClick={hideOnClick}>
              Hide
            </ButtonSimple>
          </div>
          {actionButton && <div className="ml-6">{actionButton}</div>}
        </div>
      )}
    </div>
  );
};

export default DefaultLayout;
