import { ActionBlockKey, useHideActionBlockMutation } from 'generated/graphql';
import Link from 'next/link';
import { useState } from 'react';
import Button from 'ui/generic/Button';
import { routes } from 'utils/routes';
import { renderDontShowActionItem } from './BlockActionItems';
import DefaultLayout from './DefaultLayout';
import { PatronIllustration } from './Illustrations';
import LiteralBlock from './LiteralBlock';
import { ActionBlockProps } from './Type';

export const PatronBlock = ({ setHiddenStatus }: ActionBlockProps): JSX.Element => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hideActionBlock] = useHideActionBlockMutation();

  const hide = async () => {
    const hidden = await hideActionBlock({ variables: { targetId: ActionBlockKey.BecomePatron } });
    if (hidden) {
      setDropdownOpen(false);
      setHiddenStatus(true);
    }
  };
  const dontShowActionItem = renderDontShowActionItem({ onClick: hide });

  return (
    <LiteralBlock
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      headerText="Become a Patron"
      dropdownItems={[dontShowActionItem]}
    >
      <DefaultLayout
        hideOnClick={hide}
        text="Literal is – and always will be – free to use. If you want to support our mission, please consider becoming a Patron."
        illustration={<PatronIllustration />}
        actionButton={
          <Link href={routes.patrons} passHref>
            <Button mini variant="primary">
              Become a Patron
            </Button>
          </Link>
        }
      />
    </LiteralBlock>
  );
};
