import { DropdownMobileAndDesktop } from 'components/basics/Dropdowns/DropdownMobileAndDesktop';
import { useScreenSize } from 'hooks/useScreenSize';
import { ReactNode } from 'react';
import { ButtonSimple } from 'ui/generic';
import { MoreIcon } from 'ui/icons';
import { ActionItemType, ActionList } from 'ui/specific/actionList';
import styles from './LiteralBlock.module.scss';

type Props = {
  headerText: string;
  dropdownItems: ActionItemType[];
  children: ReactNode;
  dropdownOpen: boolean;
  setDropdownOpen: (dropdownOpen: boolean) => void;
};

const LiteralBlock = ({
  headerText,
  dropdownItems,
  children,
  dropdownOpen,
  setDropdownOpen,
}: Props): JSX.Element => {
  const { smScreen } = useScreenSize();
  return (
    <div className={styles.outer}>
      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.headerText}>{headerText}</span>
          <DropdownMobileAndDesktop
            isOpen={dropdownOpen}
            setOpen={setDropdownOpen}
            isMobile={smScreen}
            button={
              <ButtonSimple variant="faded" iconOnly>
                <MoreIcon />
              </ButtonSimple>
            }
          >
            <ActionList items={dropdownItems} />
          </DropdownMobileAndDesktop>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default LiteralBlock;
