import { useHideActionBlockMutation } from 'generated/graphql';
import Link from 'next/link';
import { useState } from 'react';
import Button from 'ui/generic/Button';
import { renderDontShowActionItem } from './BlockActionItems';
import DefaultLayout from './DefaultLayout';
import { VoteIllustration } from './Illustrations';
import LiteralBlock from './LiteralBlock';
import { ActionBlockProps } from './Type';

export const FeedbackBlock = ({ setHiddenStatus }: ActionBlockProps): JSX.Element => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hideActionBlock] = useHideActionBlockMutation();

  const hide = async () => {
    const hidden = await hideActionBlock({ variables: { targetId: 'Feedback' } });
    if (hidden) {
      setDropdownOpen(false);
      setHiddenStatus(true);
    }
  };
  const dontShowActionItem = renderDontShowActionItem({ onClick: hide });

  return (
    <LiteralBlock
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      headerText="Feedback"
      dropdownItems={[dontShowActionItem]}
    >
      <DefaultLayout
        hideOnClick={hide}
        text="The whole team would love to hear your thoughts on where we should take Literal."
        illustration={<VoteIllustration />}
        actionButton={
          <Link href="/feature-ideas" passHref>
            <Button mini variant="primary">
              Vote for your favourite ideas
            </Button>
          </Link>
        }
      />
    </LiteralBlock>
  );
};

export default FeedbackBlock;
