import { ActionBlockKey } from 'generated/graphql';
import { useActionBlocksContext } from 'hooks/useActionBlocks';
import { useEffect, useMemo, useRef, useState } from 'react';
import BookRecBlock from './BookRecBlock';
import DownloadBlock from './DownloadBlock';
import { FeedbackBlock } from './FeedbackBlock';
import FollowUsBlock from './FollowUsBlock';
import FriendOfFriendsBlock from './FriendOfFriendsBlock';
import HiddenBlock from './HiddenBlock';
import LatetestBlogBlock from './LatestBlogBlock';
import { LibrarianBlock } from './LibrarianBlock';
import { PatronBlock } from './PatronBlock';
import { RandomBookBlock } from './RandomBookBlock';
import RandomWantToReadBlock from './RandomWantToReadBlock';
import SimilarTasteBlock from './SimilarTasteBlock';
type Props = {
  index: number;
};

export const ActionBlocks = ({ index }: Props): JSX.Element | null => {
  const [hidden, setHidden] = useState(false);
  const hiddenRef = useRef(hidden);
  const { availableShuffledBlocks, refetchActionBlocks } = useActionBlocksContext();

  const actionBlock = useMemo(() => {
    if (!availableShuffledBlocks) return null;
    return availableShuffledBlocks[index / 5 - 1];
  }, [availableShuffledBlocks, index]);

  // purposely refetch the action blocks when the component unmounts
  // i.e. when the user navigates away from the action block
  // this ensures that the block stays in place at the time of interaction,
  // and only gets visually hidden when the user changes focus
  useEffect(() => {
    hiddenRef.current = hidden;
  }, [hidden]);

  useEffect(() => {
    return () => {
      if (hiddenRef.current) refetchActionBlocks();
    };
  }, []);

  if (hidden) return <HiddenBlock />;

  switch (actionBlock) {
    case ActionBlockKey.Feedback:
      return <FeedbackBlock setHiddenStatus={setHidden} />;
    case ActionBlockKey.RandomBook:
      return <RandomBookBlock setHiddenStatus={setHidden} />;
    case ActionBlockKey.PeopleWithSimilarTaste:
      return <SimilarTasteBlock setHiddenStatus={setHidden} />;
    case ActionBlockKey.FollowUs:
      return <FollowUsBlock setHiddenStatus={setHidden} />;
    case ActionBlockKey.RandomWantToRead:
      return <RandomWantToReadBlock setHiddenStatus={setHidden} />;
    case ActionBlockKey.DownloadApp:
      return <DownloadBlock setHiddenStatus={setHidden} />;
    case ActionBlockKey.FriendOfFriends:
      return <FriendOfFriendsBlock setHiddenStatus={setHidden} />;
    case ActionBlockKey.BookRec:
      return <BookRecBlock setHiddenStatus={setHidden} />;
    case ActionBlockKey.LatestBlog:
      return <LatetestBlogBlock setHiddenStatus={setHidden} />;
    case ActionBlockKey.BecomePatron:
      return <PatronBlock setHiddenStatus={setHidden} />;
    case ActionBlockKey.BecomeLibrarian:
      return <LibrarianBlock setHiddenStatus={setHidden} />;
    default:
      return null;
  }
};
