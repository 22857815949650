import { ActionBlockKey, useHideActionBlockMutation } from 'generated/graphql';
import Link from 'next/link';
import { useState } from 'react';
import Button from 'ui/generic/Button';
import { routes } from 'utils/routes';
import { renderDontShowActionItem } from './BlockActionItems';
import DefaultLayout from './DefaultLayout';
import { LibrarianIllustration } from './Illustrations';
import LiteralBlock from './LiteralBlock';
import { ActionBlockProps } from './Type';

export const LibrarianBlock = ({ setHiddenStatus }: ActionBlockProps): JSX.Element => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hideActionBlock] = useHideActionBlockMutation();

  const hide = async () => {
    const hidden = await hideActionBlock({ variables: { targetId: ActionBlockKey.BecomePatron } });
    if (hidden) {
      setDropdownOpen(false);
      setHiddenStatus(true);
    }
  };
  const dontShowActionItem = renderDontShowActionItem({ onClick: hide });

  return (
    <LiteralBlock
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      headerText="Become a Librarian"
      dropdownItems={[dontShowActionItem]}
    >
      <DefaultLayout
        hideOnClick={hide}
        text={
          <p>
            Hate seeing messy book info? Frustrated that we're missing books? Want to be part of the solution?
            Consider becoming a Literal Librarian. It comes with a nice badge!
          </p>
        }
        illustration={<LibrarianIllustration />}
        actionButton={
          <Link href={routes.librarianInfo} passHref>
            <Button mini variant="primary">
              Become a Librarian
            </Button>
          </Link>
        }
      />
    </LiteralBlock>
  );
};
