import { useHideActionBlockMutation } from 'generated/graphql';
import { useState } from 'react';
import { renderDontShowActionItem } from './BlockActionItems';
import DefaultLayout from './DefaultLayout';
import styles from './DownloadBlock.module.scss';
import LiteralBlock from './LiteralBlock';
import { ActionBlockProps } from './Type';

const DownloadBlock = ({ setHiddenStatus }: ActionBlockProps): JSX.Element => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hideActionBlock] = useHideActionBlockMutation();

  const hide = async () => {
    const hidden = await hideActionBlock({ variables: { targetId: 'DownloadApp' } });
    if (hidden) {
      setDropdownOpen(false);
      setHiddenStatus(true);
    }
  };

  const dontShowActionItem = renderDontShowActionItem({ onClick: hide });

  return (
    <LiteralBlock
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      headerText="Get Literal to go"
      dropdownItems={[dontShowActionItem]}
    >
      <DefaultLayout
        hideOnClick={hide}
        text={
          <div className="flex align-center justify-center mt-4">
            <a href="https://apps.apple.com/app/literal-club/id1566119883" target="_blank" rel="noreferrer">
              <img className={styles.image} src="/img/app-store.png" alt="App store download image link" />
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=com.literal"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={styles.image}
                src="/img/google-play.png"
                alt="Google play download image link"
              />
            </a>
          </div>
        }
      />
    </LiteralBlock>
  );
};

export default DownloadBlock;
