import classNames from 'classnames';
import { ErrorMessage } from 'components/basics/Error/ErrorMessage';
import LoadingSpinner from 'components/basics/LoadingSpinner';
import { useAggregatedFeedQuery, useGetProfileFollowCountsQuery } from 'generated/graphql';
import { useAuthContext } from 'hooks/useAuth';
import React, { useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Waypoint } from 'react-waypoint';
import { EmptyState, EmptyStateConfig } from 'ui/generic';
import { ActivityGroup } from './Activity/ActivityGroup';
import { ActionBlocks } from './LiteralBlocks/ActionBlocks';

import styles from './Timeline.module.scss';

const itemsPerPage = 10;

type Props = {
  profileId?: string;
  emptyStateConfig: EmptyStateConfig;
  withPadding?: boolean;
};

const Timeline = ({ profileId, emptyStateConfig, withPadding }: Props): JSX.Element => {
  const { profile } = useAuthContext();
  const { data: countData } = useGetProfileFollowCountsQuery({
    variables: { handle: profile?.handle as string },
    skip: !profile,
  });
  const { data, loading, error, fetchMore } = useAggregatedFeedQuery({
    variables: {
      limit: itemsPerPage,
      offset: 0,
      profileId,
    },
    fetchPolicy: 'cache-first',
  });

  const amFollowingNobody = !profileId && countData?.profileByHandle.followingCount === 0;

  const loadMore = useCallback(async () => {
    if (!data || loading) return;

    fetchMore({
      variables: {
        offset: data?.aggregatedFeed?.length,
        limit: itemsPerPage,
        profileId,
      },
    });
  }, [loading, data]);

  if (loading) {
    return (
      <div
        className="flex justify-center items-center"
        style={{
          maxWidth: '560px',
          margin: '0 auto 50vw auto',
          height: '50vw',
        }}
      >
        <LoadingSpinner />
      </div>
    );
  }
  if (error) {
    console.error(error);
    return <ErrorMessage subtitle="There was an error loading your feed" />;
  }

  if (data?.aggregatedFeed?.length === 0 || amFollowingNobody) {
    return <EmptyState {...emptyStateConfig} />;
  }

  return (
    <div
      className={classNames(styles.container, {
        [styles.withPadding]: withPadding,
      })}
    >
      {data?.aggregatedFeed.map((activityGroup, i) => (
        <React.Fragment key={activityGroup.id}>
          <ActivityGroup activityGroup={activityGroup} />
          {i !== 0 && i % 5 === 0 && (
            <ErrorBoundary fallback={null}>
              <ActionBlocks index={i} />
            </ErrorBoundary>
          )}
        </React.Fragment>
      ))}

      <Waypoint topOffset="2000px" onEnter={loadMore} />
    </div>
  );
};

export default Timeline;
